import {useContext, useEffect, useMemo, useRef, useState} from 'react';
import {Panel} from "primereact/panel";
import {calculateLastAvailableYear, capitalize, cloneObject, isSmallScreenVertical, universalSort, updatePageTitle} from "../../utilities/CommonUtils";
import AuthWrapper from "../../common/AuthWrapper";
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {useSessionStorage} from "primereact/hooks";
import {DataLoadingIndicator} from "../../common/DataLoadingIndicator";
import {BlockUI} from "primereact/blockui";
import {TierListService} from "../../service/TierListService";
import TierListContainer from "./TierListContainer";
import Dexie from "dexie";
import {GameService} from "../../service/GameService";
import {AppContext} from "../../App";
import {Toast} from "primereact/toast";
import {GREEN_TO_RED_SCALE} from "../../utilities/Constants";
import {PERSON} from "../../common/Enums";
import TextInputDialog from "../../common/TextInputDialog";
import {ConfirmDialog, confirmDialog} from "primereact/confirmdialog";

export default function TierList() {
  const title = 'Tier List';
  const cache = new Dexie("tierlist.games");
  cache.version(1).stores({
    games: "name",
    updates: "lastUpdate"
  });
  const unassignedTierId = "______________________________X";
  const unassignedTierLabel = "Nieprzypisane";
  const defaultTextColor = "#212529";
  const firstTierListYear = 2024;
  const defaultTiersConfig = useMemo(() => {
    return [
      {id: "S", label: "S", tierOrder: 0, bgColor: GREEN_TO_RED_SCALE(1).name(), textColor: defaultTextColor},
      {id: "A", label: "A", tierOrder: 1, bgColor: GREEN_TO_RED_SCALE(2).name(), textColor: defaultTextColor},
      {id: "B", label: "B", tierOrder: 2, bgColor: GREEN_TO_RED_SCALE(3).name(), textColor: defaultTextColor},
      {id: "C", label: "C", tierOrder: 3, bgColor: GREEN_TO_RED_SCALE(4).name(), textColor: defaultTextColor},
      {id: "D", label: "D", tierOrder: 4, bgColor: GREEN_TO_RED_SCALE(5).name(), textColor: defaultTextColor},
      {id: unassignedTierId, label: unassignedTierLabel, tierOrder: 5, bgColor: "#ffffff", textColor: defaultTextColor}
    ];
  }, []);
  const emptyTierListData = useMemo(() => defaultTiersConfig.map(tierConfig => ({...tierConfig, games: []})), [defaultTiersConfig]);

  const [availableTierLists, setAvailableTierLists] = useState([]);
  const [availableGames, setAvailableGames] = useState([]);
  const {person} = useContext(AppContext);
  const [selectedTierList, setSelectedTierList] = useState(null);
  const [selectedLabel, setSelectedLabel] = useSessionStorage(null, "tierlist.selectedLabel");
  const [tierListData, setTierListData] = useState(emptyTierListData);
  const [adding, setAdding] = useState(false);
  const [editing, setEditing] = useState(false);
  const [originalTierListData, setOriginalTierListData] = useState(emptyTierListData);
  const [lastSelectedLabel, setLastSelectedLabel] = useState(null);
  const [showAddDialog, setShowAddDialog] = useState(false);
  const [showCopyDialog, setShowCopyDialog] = useState(false);
  const toastCenter = useRef(null);

  useEffect(() => {
    updatePageTitle(title + (selectedTierList ? ' (' + selectedTierList.label + ')' : ''));
  }, [selectedTierList]);

  useEffect(() => {
    setSelectedTierList(availableTierLists.find(tierList => tierList.label === selectedLabel));
  }, [selectedLabel, availableTierLists]);

  useEffect(() => {
    if (!selectedTierList) return;
    GameService.getLastUpdate().then(lastUpdateData => {
      cache.updates.toCollection().first().then(storedLastUpdate => {
        const oldDate = storedLastUpdate ? new Date(storedLastUpdate.lastUpdate) : undefined;
        const newDate = new Date(lastUpdateData.lastUpdate);
        if (!oldDate || newDate > oldDate) {
          cache.games.clear().then(() => TierListService.getAvailableGames())
            .then(fetchedGames => cache.games.bulkPut(fetchedGames).then(() => setAvailableGames(fetchedGames.filter(game => game.eligibleYears.includes(selectedTierList.year)))))
            .then(() => cache.updates.clear()).then(() => cache.updates.put(lastUpdateData));
        } else {
          cache.games.toArray().then(storedGames => setAvailableGames(storedGames.filter(game => game.eligibleYears.includes(selectedTierList.year))));
        }
      });
    });
  }, [selectedTierList]); // eslint-disable-line

  const buildLabel = (name, author) => '' + name + ' - ' + capitalize(author);

  const fillEmptyValuesWithDefaults = tierLists => {
    for (const tierList of tierLists) {
      for (const data of tierList.tierListData) {
        const tierConfig = defaultTiersConfig.find(config => config.id === data.id);
        if (!tierConfig) continue;
        if (!data.label) data.label = tierConfig.label;
        if (!data.bgColor) data.bgColor = tierConfig.bgColor;
        if (!data.textColor) data.textColor = tierConfig.textColor;
        if (!data.tierOrder) data.tierOrder = tierConfig.tierOrder;
      }
    }
  };

  useEffect(() => {
    TierListService.getAvailableTierLists().then(tierLists => {
      const _availableTierLists = [...tierLists];
      fillEmptyValuesWithDefaults(_availableTierLists); // This can be removed once all stored tier lists have all data filled.
      const yearlyTierLists = tierLists.filter(tierList => tierList.year !== 0);
      for (let year = calculateLastAvailableYear(); year >= firstTierListYear; year--) {
        if (!yearlyTierLists.find(tierList => tierList.year === year && tierList.author === PERSON.ANIA)) {
          _availableTierLists.push({year: year, author: PERSON.ANIA, label: buildLabel(year, PERSON.ANIA)});
        }
        if (!yearlyTierLists.find(tierList => tierList.year === year && tierList.author === PERSON.TOMEK)) {
          _availableTierLists.push({year: year, author: PERSON.TOMEK, label: buildLabel(year, PERSON.TOMEK)});
        }
      }
      _availableTierLists.sort((left, right) => universalSort(left.label, right.label, 1));
      setAvailableTierLists(_availableTierLists);
      const storedSelectedLabel = sessionStorage.getItem("tierlist.selectedLabel");
      if (!storedSelectedLabel || !_availableTierLists.find(tierList => tierList.label === JSON.parse(storedSelectedLabel))) {
        setSelectedLabel(_availableTierLists[0].label);
      }
    });
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!selectedTierList) return;
    if (selectedTierList.tierListData?.length) {
      const _tierListData = cloneObject(selectedTierList.tierListData);
      const usedGames = _tierListData.map(data => data.games).flat();
      const unusedGames = availableGames.map(game => game.id).filter(gameId => !usedGames.includes(gameId));
      let unassigned = _tierListData.find(data => data.id === unassignedTierId);
      unassigned.games.push(...unusedGames);
      setTierListData(_tierListData);
    } else {
      const _tierListData = cloneObject(emptyTierListData);
      const unassigned = _tierListData.find(data => data.id === unassignedTierId);
      unassigned.games = availableGames.map(game => game.id);
      setTierListData(_tierListData);
    }
  }, [availableGames, selectedTierList]); // eslint-disable-line

  const performSave = () => {
    const tierListToSave = {...selectedTierList};
    tierListToSave.tierListData = tierListData;
    TierListService.save(tierListToSave).then(response => {
      if (response.ok) {
        showSuccessToast("Tier Lista została zapisana.")
        response.json().then(savedTierList => {
          const _availableTierLists = [...availableTierLists];
          _availableTierLists[_availableTierLists.indexOf(selectedTierList)] = savedTierList;
          setAvailableTierLists(_availableTierLists);
          setSelectedLabel(savedTierList.label);
        });
      } else {
        response.text().then(error => showFailureToast('Tier Lista nie została zapisana. ' + error));
      }
    });
  };

  const performCopy = (newName) => {
    const tierListCopy = cloneObject(selectedTierList);
    tierListCopy.id = null;
    tierListCopy.year = 0;
    tierListCopy.name = newName;
    tierListCopy.author = person;
    tierListCopy.label = buildLabel(newName, person);
    if (availableTierLists.find(tierList => tierList.label === tierListCopy.label)) {
      showFailureToast("Tier Lista o nazwie " + newName + " już istnieje.");
      return;
    }
    setAvailableTierLists([tierListCopy, ...availableTierLists].sort((left, right) => universalSort(left.label, right.label, 1)));
    setLastSelectedLabel(selectedTierList.label);
    setSelectedLabel(tierListCopy.label);
    setOriginalTierListData(cloneObject(tierListCopy.tierListData || emptyTierListData));
    setEditing(true);
    setAdding(true);
  }

  const performCreate = (newName) => {
    const newTierList = {};
    newTierList.id = null;
    newTierList.year = 0;
    newTierList.name = newName;
    newTierList.author = person;
    newTierList.label = buildLabel(newName, person);
    if (availableTierLists.find(tierList => tierList.label === newTierList.label)) {
      showFailureToast("Tier Lista o nazwie " + newName + " już istnieje.");
      return;
    }
    setAvailableTierLists([newTierList, ...availableTierLists].sort((left, right) => universalSort(left.label, right.label, 1)));
    setLastSelectedLabel(selectedTierList.label);
    setSelectedLabel(newTierList.label);
    setOriginalTierListData(cloneObject(emptyTierListData));
    setEditing(true);
    setAdding(true);
  }

  const performRemove = async (id) => {
    TierListService.delete(id).then(response => {
      if (response.ok) {
        setAvailableTierLists(availableTierLists.filter(tierList => tierList.id !== id));
        setSelectedLabel(availableTierLists[0].label);
        showSuccessToast('Tier Lista została usunięta.');
      } else {
        response.text().then(error => showFailureToast('Tier Lista nie została usunięta. ' + error));
      }
    });
  }

  const showSuccessToast = (message) => toastCenter.current.show({
    severity: 'success',
    summary: 'Sukces!',
    detail: message
  });

  const showFailureToast = (message) => toastCenter.current.show({
    severity: 'error',
    summary: 'Błąd!',
    detail: message
  });

  const headerTemplate = () => {
    return <div className={"p-panel-header bg-white" + (isSmallScreenVertical() ? " d-flex flex-column" : "")}>
      <h3>{title + (selectedTierList ? ' (' + selectedTierList.label + ')' : '')}</h3>
      <div>
        <AuthWrapper>
          {editing ? <>
              <Button label="Anuluj" icon="pi pi-undo" className="me-2" onClick={() => {
                if (adding) {
                  setAvailableTierLists(availableTierLists.filter(tierList => tierList.label !== selectedLabel));
                }
                setEditing(false);
                setAdding(false);
                setSelectedLabel(lastSelectedLabel);
                setTierListData(originalTierListData);
              }}/>
              <Button label="Zapisz" icon="pi pi-save" className="me-2" onClick={() => {
                performSave();
                setEditing(false);
                setAdding(false);
              }}/>
            </> :
            <>
              <Button className="me-2" icon="pi pi-plus-circle" tooltip="Dodaj" tooltipOptions={{position: "bottom"}}
                      onClick={() => setShowAddDialog(true)}/>
              <Button className="me-2" icon="pi pi-clone" tooltip="Kopiuj" tooltipOptions={{position: "bottom"}}
                      onClick={() => setShowCopyDialog(true)}/>
              <Button className="me-2" icon="pi pi-pencil" tooltip="Edytuj" tooltipOptions={{position: "bottom"}}
                      disabled={selectedTierList ? selectedTierList.author !== person : true}
                      onClick={() => {
                        setEditing(true);
                        setOriginalTierListData(cloneObject(tierListData));
                        setLastSelectedLabel(selectedTierList.label);
                      }}/>
              <Button className="me-2" icon="pi pi-trash" severity="danger" tooltip="Usuń" tooltipOptions={{position: "bottom"}}
                      disabled={selectedTierList ? selectedTierList.author !== person || selectedTierList.year !== 0 : true}
                      onClick={() => {
                        confirmDialog({
                          acceptLabel: 'Tak',
                          rejectLabel: 'Nie',
                          message: 'Czy na pewno usunąć tier listę ' + selectedTierList.name + '?',
                          header: 'Potwierdź usuwanie',
                          icon: 'pi pi-exclamation-triangle',
                          acceptClassName: 'p-button-danger',
                          accept: () => performRemove(selectedTierList.id)
                        });
                      }}/>
              <Dropdown options={availableTierLists.map(tierList => tierList.label)} value={selectedLabel} onChange={event => setSelectedLabel(event.value)}/>
            </>}
        </AuthWrapper>
      </div>
    </div>
  }

  return <>
    <Panel className="data-list-panel" headerTemplate={headerTemplate} pt={{content: {className: "data-list-panel-content tierlist-panel-content"}}}>
      <ConfirmDialog/>
      <Toast ref={toastCenter} position="center" className="text-center"/>
      <BlockUI blocked={tierListData === emptyTierListData} template={<DataLoadingIndicator/>} containerClassName="flex-grow-1">
        <TextInputDialog visible={showAddDialog} header="Dodaj tier listę" placeholder="Nowa tier lista"
                         onHide={() => setShowAddDialog(false)}
                         onConfirm={performCreate}/>
        <TextInputDialog visible={showCopyDialog} header="Kopiuj tier listę" placeholder={'' + (selectedTierList?.year || selectedTierList?.name) + ' - Kopia'}
                         onHide={() => setShowCopyDialog(false)}
                         onConfirm={performCopy}/>
        <TierListContainer availableGames={availableGames} tierListData={tierListData} setTierListData={setTierListData}
                           editing={editing} unassignedTierId={unassignedTierId}/>
      </BlockUI>
    </Panel>
  </>
}
