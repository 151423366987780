import {useState} from "react";
import {Button} from "primereact/button";
import {InputText} from "primereact/inputtext";
import {Dialog} from "primereact/dialog";

export default function TextInputDialog({visible, header, placeholder, onHide, onConfirm}) {
  const [value, setValue] = useState("");

  const closeDialog = () => {
    onHide();
    setValue("");
  }

  return <Dialog header={header} visible={visible} onHide={closeDialog}
                 footer={() => <>
                   <Button label="Anuluj" severity="secondary" onClick={closeDialog}/>
                   <Button label="OK" disabled={!value} onClick={() => {
                     onConfirm(value);
                     closeDialog();
                   }}/>
                 </>}>
    <InputText placeholder={placeholder} value={value} onChange={event => setValue(event.target.value)}/>
  </Dialog>
}