import {useEffect, useMemo, useState} from 'react';
import {Panel} from "primereact/panel";
import {DataLoadingIndicator} from "../../common/DataLoadingIndicator";
import {calculateLastAvailableYear, isSmallScreen, isSmallScreenVertical, updatePageTitle} from "../../utilities/CommonUtils";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import AuthWrapper from "../../common/AuthWrapper";
import {Button} from "primereact/button";
import {RankingService} from "../../service/RankingService";
import RankingChange from "./RankingChange";
import {YEAR_START} from "../../utilities/Constants";
import {Dropdown} from "primereact/dropdown";
import {useSessionStorage} from "primereact/hooks";

export default function Ranking() {
  const title = 'Ranking';
  const rankingsEnabled = false;
  const lastAvailableYear = rankingsEnabled ? calculateLastAvailableYear() : 2024;
  const emptyRankingData = useMemo(() => ({
    rankedGames: null
  }), []);

  const [availableYears, setAvailableYears] = useState([]);
  const [year, setYear] = useSessionStorage(lastAvailableYear, "ranking.year");
  const [sortField, setSortField] = useSessionStorage("rankTotal", "ranking.sortField");
  const [sortOrder, setSortOrder] = useSessionStorage(1, "ranking.sortOrder");
  const [rankingData, setRankingData] = useState(emptyRankingData);

  useEffect(() => {
    updatePageTitle(title + ' ' + year);
  }, [year]);

  useEffect(() => {
    let years = [];
    for (let year = lastAvailableYear; year >= YEAR_START; year--) {
      years.push(year);
    }
    setAvailableYears(years);
  }, [lastAvailableYear]);

  useEffect(() => {
    if (year) {
      RankingService.get(year).then(data => setRankingData(data));
    }
  }, [year]);

  const headerTemplate = () => {
    return <div className={"p-panel-header bg-white" + (isSmallScreenVertical() ? " d-flex flex-column" : "")}>
      <h3>{title + ' ' + year}</h3>
      <div>
        {!rankingsEnabled && <>
          <small className="me-2 pi pi-info-circle"/>
          <small className="me-2 fst-italic">Rankingi po roku {lastAvailableYear} zostały wyłączone</small></>
        }
        <AuthWrapper>
          {/*<Link to={"/ranking/" + year + "/edit"}>*/}
          <Button label="Edytuj" icon="pi pi-pencil" className="me-2" disabled={!rankingsEnabled}/>
          {/*</Link>*/}
        </AuthWrapper>
        <Dropdown options={availableYears} value={year} onChange={event => setYear(event.value)}/><br/>
      </div>
    </div>
  }

  return <>
    <Panel className="data-list-panel" headerTemplate={headerTemplate} pt={{content: {className: "data-list-panel-content"}}}>
      <DataTable className="flex-grow-1" value={rankingData.rankedGames}
                 sortField={sortField} sortOrder={sortOrder}
                 emptyMessage="Ranking niedostępny."
                 scrollable scrollHeight="flex"
                 loading={rankingData === emptyRankingData} loadingIcon={<DataLoadingIndicator/>}
                 stripedRows
                 onSort={event => {
                   setSortField(event.sortField);
                   setSortOrder(event.sortOrder);
                 }}>
        <Column field="rankAnia" header="Poz. Ani" sortable style={{width: "8%"}} align="center" hidden={isSmallScreen()}/>
        <Column field="rankTomek" header="Poz. Tomka" sortable style={{width: "8%"}} align="center" hidden={isSmallScreen()}/>
        <Column field="rankDiff" header="Różnica" sortable style={{width: "8%"}} align="center" hidden={isSmallScreen()}/>
        <Column field="rankTotal" header="Poz." sortable style={{width: "8%"}} align="center" className="fw-bold"/>
        <Column field="name" header="Tytuł"/>
        <Column header="Zmiana" style={{width: "8%"}} align="center" body={entry => <RankingChange type={entry.changeType} value={entry.changeValue}/>}/>
      </DataTable>
    </Panel>
  </>
}
