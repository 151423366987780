import {DndContext, DragOverlay} from "@dnd-kit/core";
import TierListTile from "./TierListTile";
import {arrayMove, SortableContext} from "@dnd-kit/sortable";
import TierListBox from "./TierListBox";
import {useState} from "react";
import {InputText} from "primereact/inputtext";
import {ColorPicker} from "../../common/ColorPicker";

export default function TierListContainer(props) {

  const [activeId, setActiveId] = useState(null);

  const getTierId = id => {
    if (props.tierListData.map(data => data.id).includes(id)) {
      return id;
    }
    return props.tierListData.find(data => data.games.includes(id)).id;
  }

  const handleDragStart = event => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = event => {
    if (!event.over) return;
    const gameId = event.active.id;
    const overId = event.over.id;
    const oldTierId = getTierId(gameId);
    const newTierId = getTierId(overId);
    const oldTierGames = props.tierListData.find(data => data.id === oldTierId).games;
    const newTierGames = props.tierListData.find(data => data.id === newTierId).games;
    const activeIndex = oldTierGames.indexOf(gameId);
    const overIndex = newTierGames.indexOf(overId);
    if (oldTierId === newTierId) {
      if (activeIndex !== overIndex) {
        const newTierListData = [...props.tierListData];
        newTierListData.find(data => data.id === newTierId).games = arrayMove(newTierGames, activeIndex, overIndex);
        props.setTierListData(newTierListData);
      }
    } else {
      let newIndex;
      if (props.tierListData.map(data => data.id).includes(overId)) {
        newIndex = newTierGames.length + 1;
      } else {
        const isAfterLastItem =
          event.over &&
          overIndex === newTierGames.length - 1 &&
          event.active.rect.left > event.over.rect.left + event.over.rect.width;
        const modifier = isAfterLastItem ? 1 : 0;
        newIndex = overIndex >= 0 ? overIndex + modifier : newTierGames.length + 1;
      }
      const newTierListData = [...props.tierListData];
      newTierListData.find(data => data.id === oldTierId).games = oldTierGames.filter(id => id !== gameId);
      newTierListData.find(data => data.id === newTierId).games = [...newTierGames.slice(0, newIndex), oldTierGames[activeIndex], ...newTierGames.slice(newIndex, newTierGames.length)];
      props.setTierListData(newTierListData);
    }
    setActiveId(null);
  };

  return <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
    <table className="w-100">
      <tbody>
      {props.tierListData.filter(data => props.editing || data.id !== props.unassignedTierId).map((tier, i) => {
        const gamesInTier = props.tierListData.find(data => data.id === tier.id).games;
        return <tr key={tier.id} className="tierlist-row">
          <td className="tierlist-header" style={{backgroundColor: tier.bgColor, color: tier.textColor}}>
            {props.editing && tier.id !== props.unassignedTierId ?
              <>
                <InputText style={{borderColor: '#212529', backgroundColor: tier.bgColor, color: tier.textColor}} defaultValue={tier.label} onBlur={event => {
                  tier.label = event.target.value || tier.label;
                  if (tier.id !== props.unassignedTierId) {
                    tier.id = event.target.value || tier.id;
                  }
                  props.setTierListData([...props.tierListData]);
                }} size={5} className="text-center"/>
                <div className="d-flex gap-2 justify-content-center mt-1">
                  <ColorPicker color={tier.bgColor} onChange={value => {
                    tier.bgColor = value || tier.bgColor;
                    props.setTierListData([...props.tierListData]);
                  }}/>
                  <ColorPicker color={tier.textColor} text="T" onChange={value => {
                    tier.textColor = value || tier.textColor;
                    props.setTierListData([...props.tierListData]);
                  }}/>
                </div>
              </>
              : tier.label}
          </td>
          <SortableContext items={gamesInTier} disabled={!props.editing}>
            <TierListBox tier={tier}>
              {gamesInTier.map(gameId => props.availableGames.find(game => game.id === gameId)).filter(game => game)
                .map(game => <TierListTile key={game.id} game={game}/>)}
            </TierListBox>
          </SortableContext>
        </tr>
      })}
      </tbody>
    </table>
    <DragOverlay>{activeId ? <TierListTile game={props.availableGames.find(game => game.id === activeId)}/> : null}</DragOverlay>
  </DndContext>

}
