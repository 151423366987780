export const ApiService = {
  get(url, id, param) {
    return fetch(url + (id ? '/' + id : '') + (param ? '/' + param : '')).then(response => response.json());
  },
  save(url, object) {
    return fetch(url + (object.id ? '/' + object.id : ''), {
      method: (object.id) ? 'PUT' : 'POST',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(object),
    });
  },
  delete(url, id) {
    return fetch(url + '/' + id, {
      method: 'DELETE',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json'
      }
    });
  }
}
