import {useEffect, useRef, useState} from 'react';
import {Tooltip} from "react-tooltip";
import HistoryForm from "./HistoryForm";
import {Toast} from "primereact/toast";
import {FilterMatchMode, FilterService} from "primereact/api";
import {ConfirmDialog, confirmDialog} from 'primereact/confirmdialog';
import {Panel} from "primereact/panel";
import {Dialog} from "primereact/dialog";
import {DataTable} from "primereact/datatable";
import {Column} from "primereact/column";
import {Button} from "primereact/button";
import AuthWrapper from "../../common/AuthWrapper";
import {MultiSelect} from "primereact/multiselect";
import {DataLoadingIndicator} from "../../common/DataLoadingIndicator";
import {dateToString, isSmallScreen, isSmallScreenVertical, minutesToFormattedTime, updatePageTitle} from "../../utilities/CommonUtils";
import {InputNumber} from "primereact/inputnumber";
import {Slider} from "primereact/slider";
import {Divider} from "primereact/divider";
import {ToggleButton} from "primereact/togglebutton";
import {Dropdown} from "primereact/dropdown";
import {HistoryService} from "../../service/HistoryService";
import {TriStateCheckbox} from "primereact/tristatecheckbox";
import {useSessionStorage} from "primereact/hooks";
import {useParams} from "react-router-dom";
import {AutoComplete} from "primereact/autocomplete";

export default function HistoryList() {
  const title = 'Historia rozgrywek';
  const toast = useRef(null);
  const {goToId} = useParams();
  FilterService.register("playersListFilter", players => playersListFilter.length === 0 || playersListFilter.every(player => players.includes(player)));
  FilterService.register("winnerFilter", winners => !winnerFilter || winners.indexOf(winnerFilter) !== -1);
  const [showMoreFilters, setShowMoreFilters] = useSessionStorage(false, "historylist.showMoreFilters");
  const [dataLoaded, setDataLoaded] = useState(false);
  const [histories, setHistories] = useState([]);
  const [showForm, setShowForm] = useState(false);
  const [editedHistoryId, setEditedHistoryId] = useState(null);
  const [distinctPlayers, setDistinctPlayers] = useState(['Ania', 'Tomek']);
  const [globalFilterValue, setGlobalFilterValue] = useSessionStorage('', "historylist.globalFilterValue");
  const [globalFilterSuggestions, setGlobalFilterSuggestions] = useState([]);
  const [playersCountFilter, setPlayersCountFilter] = useSessionStorage(null, "historylist.playersCountFilter");
  const [playedBetweenFilterFrom, setPlayedBetweenFilterFrom] = useSessionStorage('', "historylist.playedBetweenFilterFrom");
  const [playedBetweenFilterTo, setPlayedBetweenFilterTo] = useSessionStorage(dateToString(new Date()), "historylist.playedBetweenFilterTo");
  const [playersListFilter, setPlayersListFilter] = useState([]);
  const [playersListFilterStored, setPlayersListFilterStored] = useSessionStorage([], "historylist.playersListFilter");
  const [winnerFilter, setWinnerFilter] = useSessionStorage('', "historylist.winnerFilter");
  const [coopFilter, setCoopFilter] = useSessionStorage(null, "historylist.coopFilter");
  const [campaignFilter, setCampaignFilter] = useSessionStorage(null, "historylist.campaignFilter");
  const [sortField, setSortField] = useSessionStorage("dateOfGame", "historylist.sortField");
  const [sortOrder, setSortOrder] = useSessionStorage(-1, "historylist.sortOrder");
  const [resultCount, setResultCount] = useState(0);
  const [filters, setFilters] = useSessionStorage({
    global: {value: null, matchMode: FilterMatchMode.CONTAINS},
    'players.length': {value: null, matchMode: FilterMatchMode.EQUALS},
    dateOfGame: {value: null, matchMode: FilterMatchMode.BETWEEN},
    players: {value: null, matchMode: "playersListFilter"},
    winners: {value: null, matchMode: "winnerFilter"},
    coop: {value: null, matchMode: FilterMatchMode.EQUALS},
    campaign: {value: null, matchMode: FilterMatchMode.EQUALS}
  }, "historylist.filters");
  const toggleableFilters = [
    {name: "dateOfGame", label: "Data rozgrywki"},
    {name: "playersCount", label: "Liczba graczy"},
    {name: "players", label: "Gracze"},
    {name: "winner", label: "Zwycięzca"},
    {name: "coop", label: "Co-op"},
    {name: "campaign", label: "Kampania"}
  ];
  const toggleableColumns = [
    {field: "playersCount", header: "# Graczy", sortable: true, filter: true, align: "center"},
    {field: "players", header: "Gracze", sortable: true, filter: true, align: "left"},
    {field: "winners", header: "Zwycięzca", sortable: true, filter: true, align: "left"},
    {field: "timeTaken", header: "Czas", sortable: true, filter: false, align: "center"},
    {field: "remarks", header: "Uwagi", sortable: false, filter: false, align: "center", italic: true}
  ]
  const defaultVisibleColumns = ["playersCount", "winners", "remarks"];
  const smallScreenVisibleColumns = [];
  const [visibleColumns, setVisibleColumns] = useSessionStorage(toggleableColumns.filter(column => {
    const columnSet = isSmallScreen() ? smallScreenVisibleColumns : defaultVisibleColumns;
    return columnSet.indexOf(column.field) !== -1;
  }), "historylist.visibleColumns");
  const [visibleFilters, setVisibleFilters] = useSessionStorage(toggleableFilters.filter(filter => {
    return isSmallScreen() ? filter.name === "dateOfGame" : true;
  }), "historylist.visibleFilters");
  const onColumnToggle = (event) => {
    const selectedColumns = event.value;
    const orderedSelectedColumns = toggleableColumns.filter(column => selectedColumns.some((selectedColumn) => selectedColumn.field === column.field));
    setVisibleColumns(orderedSelectedColumns);
    event.stopPropagation();
  };
  const onFilterToggle = (event) => {
    const selectedFilters = event.value;
    const orderedSelectedFilters = toggleableFilters.filter(filter => selectedFilters.some((selectedFilter) => selectedFilter.name === filter.name));
    setVisibleFilters(orderedSelectedFilters);
    event.stopPropagation();
  };
  const minPlayerCount = useRef(null);
  const maxPlayerCount = useRef(null);
  const playersOptions = useRef([]);
  const datatable = useRef(null);

  useEffect(() => {
    updatePageTitle(title);
  }, []);

  useEffect(() => {
    if (!showForm) {
      HistoryService.getAll().then(data => {
        setHistories(data);
        const distinctPlayers = data.map(history => history.players).flat().filter((value, index, self) => self.indexOf(value) === index);
        setDistinctPlayers(distinctPlayers);
        minPlayerCount.current = Math.min(...data.map(history => history.players.length));
        maxPlayerCount.current = Math.max(...data.map(history => history.players.length));
        playersOptions.current = data.map(history => history.players)
          .flat()
          .filter((value, index, self) => self.indexOf(value) === index)
          .map(player => ({value: player, label: player}));
        setDataLoaded(true);
      });
    }
  }, [showForm]);

  useEffect(() => {
    if (dataLoaded) {
      setPlayersListFilter(playersListFilterStored);
    }
  }, [dataLoaded, playersListFilterStored]);

  useEffect(() => {
    if (goToId) {
      datatable.current.filter(goToId, "gameId", "equals");
    }
  }, [goToId]);

  useEffect(() => {
    if (datatable.current !== null) {
      setResultCount(datatable.current.getTable().querySelectorAll('tbody.p-datatable-tbody tr:not(.p-datatable-emptymessage)').length);
    }
  }, [histories]);

  const onGlobalFilterChange = (value) => {
    let _filters = {...filters};

    _filters['global'].value = value.trim();

    setFilters(_filters);
    setGlobalFilterValue(value);
  };

  const onPlayersCountFilterChange = value => {
    setPlayersCountFilter(value);
    let _filters = {...filters};
    _filters['players.length'].value = value;
    setFilters(_filters);
  };

  const onPlayedBetweenFilterChange = (from, to) => {
    setPlayedBetweenFilterFrom(from);
    setPlayedBetweenFilterTo(to);
    let _filters = {...filters};
    _filters['dateOfGame'].value = [from, to];
    setFilters(_filters);
  };

  const onPlayersListFilterChange = (event) => {
    setPlayersListFilter(event.value || []);
    setPlayersListFilterStored(event.value || []);
    let _filters = {...filters};
    _filters['players'].value = [event.value || []];
    setFilters(_filters);
    if (event.stopPropagation) {
      event.stopPropagation();
    }
  };

  const onWinnerFilterChange = (value) => {
    setWinnerFilter(value);
    let _filters = {...filters};
    _filters['winners'].value = [value];
    setFilters(_filters);
  };

  const onCoopFilterChange = (value) => {
    setCoopFilter(value);
    let _filters = {...filters};
    _filters['coop'].value = value;
    setFilters(_filters);
  };

  const onCampaignFilterChange = (value) => {
    setCampaignFilter(value);
    let _filters = {...filters};
    _filters['campaign'].value = value;
    setFilters(_filters);
  };

  const resetAdvancedFilters = () => {
    onPlayersCountFilterChange(null);
    onPlayedBetweenFilterChange('', dateToString(new Date()));
    onPlayersListFilterChange([]);
    onWinnerFilterChange('');
    onCoopFilterChange(null);
    onCampaignFilterChange(null);
  }

  const confirmRemove = (id, name, date) => {
    confirmDialog({
      acceptLabel: 'Tak',
      rejectLabel: 'Nie',
      message: 'Czy na pewno usunąć rozgrywkę w ' + name + ' / ' + date + '?',
      header: 'Potwierdź usuwanie',
      icon: 'pi pi-exclamation-triangle',
      acceptClassName: 'p-button-danger',
      accept: () => performRemove(id)
    });
  };

  const performRemove = async (id) => {
    HistoryService.delete(id).then(response => {
      if (response.ok) {
        let _histories = [...histories].filter(history => history.id !== id);
        setHistories(_histories);
        showSuccessToast('Rozgrywka została usunięta.');
      } else {
        response.text().then(error => showFailureToast('Rozgrywka nie została usunięta. ' + error));
      }
    });
  }

  const closeForm = () => toggleForm(null, false);
  const showSuccessToast = (message) => toast.current.show({severity: 'success', summary: 'Sukces!', detail: message});
  const showFailureToast = (message) => toast.current.show({severity: 'error', summary: 'Błąd!', detail: message});

  const toggleForm = (id, show) => {
    setEditedHistoryId(id);
    setShowForm(show);
  };

  const actionsTemplate = (history) => {
    return <AuthWrapper><span className="text-nowrap">
      <Button size="small" severity="primary" className="me-1" icon="pi pi-pencil" onClick={() => toggleForm(history.id, true)}/>
      <Button size="small" severity="danger" className="me-1" icon="pi pi-trash" onClick={() => confirmRemove(history.id, history.game, history.dateOfGame)}/>
    </span></AuthWrapper>
  }

  const winnersTemplate = (history) => {
    return history.winners.join(", ");
  }

  const playersTemplate = (history) => {
    return history.players.join(", ");
  }

  const playersTooltipTemplate = (history) => {
    return <span className="tooltip-cursor"
                 data-tooltip-id="players-tooltip"
                 data-tooltip-html={history.players.join(", ")}>{history.players.length}</span>
  }

  const customBodyTemplates = {
    "winners": winnersTemplate,
    "players": playersTemplate,
    "playersCount": playersTooltipTemplate,
    "timeTaken": history => minutesToFormattedTime(history.timeTaken)
  };

  const headerTemplate = () => {
    return <>
      <div className={"p-panel-header bg-white" + (isSmallScreenVertical() ? " d-flex flex-column" : "")}>
        <h3>{title} ({resultCount})</h3>
        <div className="d-flex justify-content-evenly">
          <AuthWrapper><Button className="me-2" icon="pi pi-plus-circle" tooltip="Dodaj wpis" tooltipOptions={{position: "bottom"}}
                               onClick={() => toggleForm(null, true)}/></AuthWrapper>
          <MultiSelect value={visibleColumns} options={toggleableColumns} optionLabel="header" onChange={onColumnToggle}
                       dropdownIcon="pi pi-rotate-90 pi-bars" className="me-2 icon-only" tooltip="Kolumny" tooltipOptions={{position: "bottom"}}/>
          <ToggleButton className="filter-toggle me-2" onLabel="" offLabel="" onIcon="pi pi-filter" offIcon="pi pi-filter"
                        tooltip="Dodatkowe filtrowanie" tooltipOptions={{position: "bottom"}}
                        checked={showMoreFilters} onChange={event => setShowMoreFilters(event.value)}/>
          <span className="p-input-icon-left">
            <i className="pi pi-search" style={{zIndex: 1}}/>
            <AutoComplete value={globalFilterValue} onChange={event => onGlobalFilterChange(event.target.value)} placeholder="Szukaj..."
                          inputClassName="w-100" inputStyle={{paddingLeft: "2.5rem"}} suggestions={globalFilterSuggestions}
                          completeMethod={event => setGlobalFilterSuggestions(histories.map(history => history.game).filter((value, index, self) => self.indexOf(value) === index).filter(game => event.query ? game.toLowerCase().indexOf(event.query.toLowerCase()) !== -1 : true))}/>
            <i className="btn-close end-0 pt-0 pe-3" hidden={!globalFilterValue} onClick={() => onGlobalFilterChange("")}/>
          </span>
        </div>
      </div>
    </>
  }

  const singleFilterClassName = filterName => visibleFilters.some(filter => filter.name === filterName) ? "d-flex align-items-center gap-3" : "d-none";

  const advancedFilters = <div className="d-flex align-items-center gap-4 horizontally-scrollable">
    <div className="d-flex align-items-center">
      <MultiSelect value={visibleFilters} options={toggleableFilters} optionLabel="label" onChange={onFilterToggle}
                   dropdownIcon="pi pi-sliders-h" className="me-2 icon-only" tooltip="Filtry" tooltipOptions={{position: "bottom"}}/>
      <Button icon="pi pi-filter-slash" tooltip="Resetuj filtrowanie" tooltipOptions={{position: "bottom"}} onClick={resetAdvancedFilters}/>
    </div>
    <div className={singleFilterClassName("dateOfGame")}>
      <Divider layout="vertical" className="m-0"/>
      <span>Grane</span>
      <span className="d-flex align-items-center">
        <input type="date" value={playedBetweenFilterFrom} onChange={event => onPlayedBetweenFilterChange(event.target.value, playedBetweenFilterTo)}/>
        -
        <input type="date" value={playedBetweenFilterTo} onChange={event => onPlayedBetweenFilterChange(playedBetweenFilterFrom, event.target.value)}/>
      </span>
    </div>
    <div className={singleFilterClassName("playersCount")}>
      <Divider layout="vertical" className="m-0"/>
      <span>Liczba graczy</span>
      <span>
        <InputNumber inputClassName="text-center" size={12} inputStyle={{height: "2rem"}} min={minPlayerCount.current} max={maxPlayerCount.current}
                     value={playersCountFilter} onChange={event => onPlayersCountFilterChange(event.value)}/>
        <Slider min={minPlayerCount.current} max={maxPlayerCount.current} value={playersCountFilter}
                onChange={event => onPlayersCountFilterChange(event.value)}/>
      </span>
    </div>
    <div className={singleFilterClassName("players")}>
      <Divider layout="vertical" className="m-0"/>
      <span>Gracze</span>
      <MultiSelect options={playersOptions.current} onChange={event => onPlayersListFilterChange(event)} value={playersListFilter}
                   placeholder={"Wybierz..."} display="chip" filter={true} showSelectAll={false} style={{height: "2rem", lineHeight: "1rem", width: "18rem"}}/>
    </div>
    <div className={singleFilterClassName("winner")}>
      <Divider layout="vertical" className="m-0"/>
      <span>Zwycięzca</span>
      <Dropdown options={playersOptions.current} style={{height: "2rem", lineHeight: "1rem"}} value={winnerFilter}
                onChange={event => onWinnerFilterChange(event.value)} showClear={winnerFilter !== ''}/>
    </div>
    <div className={singleFilterClassName("coop")}>
      <Divider layout="vertical" className="m-0"/>
      <span>Co-op</span>
      <TriStateCheckbox value={coopFilter} onChange={event => onCoopFilterChange(event.value)}/>
    </div>
    <div className={singleFilterClassName("campaign")}>
      <Divider layout="vertical" className="m-0"/>
      <span>Kampania</span>
      <TriStateCheckbox value={campaignFilter} onChange={event => onCampaignFilterChange(event.value)}/>
    </div>
  </div>;

  return <>
    <Panel className="data-list-panel" headerTemplate={headerTemplate} pt={{content: {className: "data-list-panel-content"}}}>
      <Toast ref={toast} position="center" className="text-center"/>
      <ConfirmDialog/>
      <Dialog visible={showForm} resizable={false} showHeader={false} onHide={() => setShowForm(false)}>
        <HistoryForm id={editedHistoryId} playersSuggestions={distinctPlayers} onClose={closeForm} showFailureToast={showFailureToast}
                     onSuccessfulSubmit={() => {
                       closeForm();
                       showSuccessToast('Zmiany zostały zapisane.');
                     }}/>
      </Dialog>
      <Tooltip id="players-tooltip" delayShow={500} place="left" className="tooltip-content"/>
      <DataTable ref={datatable} className="flex-grow-1" value={histories} filters={filters} globalFilterFields={['game']}
                 onValueChange={(dataArray) => setResultCount(dataArray.length)}
                 sortField={sortField} sortOrder={sortOrder}
                 emptyMessage="Brak wyników."
                 scrollable scrollHeight="flex"
                 loading={histories.length === 0} loadingIcon={<DataLoadingIndicator/>}
                 stripedRows
                 header={showMoreFilters ? advancedFilters : null}
                 onSort={event => {
                   setSortField(event.sortField);
                   setSortOrder(event.sortOrder);
                 }}>
        <Column field="dateOfGame" header="Data" sortable filter showFilterMenu={false} align="center"/>
        <Column field="game" header="Gra" sortable/>
        {visibleColumns.map(column =>
          <Column key={column.field} field={column.field} header={column.header} align={column.align}
                  sortable={column.sortable} filter={column.filter} showFilterMenu={false}
                  body={customBodyTemplates[column.field]} bodyClassName={column.italic ? "fst-italic" : ""}
                  sortFunction={column.field === "playersCount" ? event => {
                    event.data.sort((left, right) => {
                      return (left.players.length > right.players.length ? 1 : -1) * event.order;
                    });
                    return event.data;
                  } : null}/>
        )}
        <Column body={actionsTemplate}/>
      </DataTable>
    </Panel>
  </>
}
