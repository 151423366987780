import {ApiService} from "./ApiService";

const API = {
  BASE: '/api/tierlist',
  GAMES: '/api/tierlist/games',
  LISTS: '/api/tierlist/lists'
};

export const TierListService = {
  getAvailableGames() {
    return ApiService.get(API.GAMES);
  },
  getAvailableTierLists() {
    return ApiService.get(API.LISTS);
  },
  save(tierList) {
    return ApiService.save(API.BASE, tierList);
  },
  delete(id) {
    return ApiService.delete(API.BASE, id);
  }
}
