import {DndContext, DragOverlay} from "@dnd-kit/core";
import TierListTile from "./TierListTile";
import {arrayMove, SortableContext} from "@dnd-kit/sortable";
import TierListBox from "./TierListBox";
import {useState} from "react";

export default function TierListContainer(props) {

  const [activeId, setActiveId] = useState(null);

  const getTierId = id => {
    if (props.tiersConfig.map(tier => tier.id).includes(id)) {
      return id;
    }
    return props.tierListData.find(data => data.games.includes(id)).tier;
  }

  const handleDragStart = event => {
    setActiveId(event.active.id);
  };

  const handleDragEnd = event => {
    if (!event.over) return;
    const gameId = event.active.id;
    const overId = event.over.id;
    const oldTierId = getTierId(gameId);
    const newTierId = getTierId(overId);
    const oldTierGames = props.tierListData.find(data => data.tier === oldTierId).games;
    const newTierGames = props.tierListData.find(data => data.tier === newTierId).games;
    const activeIndex = oldTierGames.indexOf(gameId);
    const overIndex = newTierGames.indexOf(overId);
    if (oldTierId === newTierId) {
      if (activeIndex !== overIndex) {
        const newTierListData = [...props.tierListData];
        newTierListData.find(data => data.tier === newTierId).games = arrayMove(newTierGames, activeIndex, overIndex);
        props.setTierListData(newTierListData);
      }
    } else {
      let newIndex;
      if (props.tiersConfig.map(tier => tier.id).includes(overId)) {
        newIndex = newTierGames.length + 1;
      } else {
        const isAfterLastItem =
          event.over &&
          overIndex === newTierGames.length - 1 &&
          event.active.rect.left > event.over.rect.left + event.over.rect.width;
        const modifier = isAfterLastItem ? 1 : 0;
        newIndex = overIndex >= 0 ? overIndex + modifier : newTierGames.length + 1;
      }
      const newTierListData = [...props.tierListData];
      newTierListData.find(data => data.tier === oldTierId).games = oldTierGames.filter(id => id !== gameId);
      newTierListData.find(data => data.tier === newTierId).games = [...newTierGames.slice(0, newIndex), oldTierGames[activeIndex], ...newTierGames.slice(newIndex, newTierGames.length)];
      props.setTierListData(newTierListData);
    }
    setActiveId(null);
  };

  return <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
    <table className="w-100">
      <tbody>
      {props.tiersConfig.filter(tier => props.editing || tier.id !== props.unassignedTierId).map(tier => {
        const gamesInTier = props.tierListData.find(data => data.tier === tier.id).games;
        return <tr key={tier.id} className="tierlist-row">
          <td className="tierlist-header" style={{backgroundColor: tier.color, color: tier.textColor}}>{tier.label}</td>
          <SortableContext items={gamesInTier} disabled={!props.editing}>
            <TierListBox tier={tier}>
              {gamesInTier.map(gameId => props.availableGames.find(game => game.id === gameId)).filter(game => game)
                .map(game => <TierListTile key={game.id} game={game}/>)}
            </TierListBox>
          </SortableContext>
        </tr>
      })}
      </tbody>
    </table>
    <DragOverlay>{activeId ? <TierListTile game={props.availableGames.find(game => game.id === activeId)}/> : null}</DragOverlay>
  </DndContext>

}
