export const GAME_SOURCE = {
  "PURCHASE": "Zakup",
  "GIFT": "Prezent",
  "PRIZE": "Konkurs"
}

export const BOX_SIZE = {
  "SMALL": "Mały",
  "BIG": "Duży"
}

export const TIME_UNIT = {
  DAY: {label: "dni", value: 1},
  WEEK: {label: "tygodni", value: 7},
  MONTH: {label: "miesięcy", value: 30},
  YEAR: {label: "lat", value: 365}
}

export const GAME_SOURCE_OPTIONS = Object.keys(GAME_SOURCE).map(key => ({value: key, label: GAME_SOURCE[key]}))

export const BOX_SIZE_OPTIONS = Object.keys(BOX_SIZE).map(key => ({value: key, label: BOX_SIZE[key]}))

export const TIME_UNIT_OPTIONS = Object.keys(TIME_UNIT).map(key => TIME_UNIT[key]);

export const PERSON = {
  ANIA: "ANIA",
  TOMEK: "TOMEK"
}