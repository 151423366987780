import {InputText} from "primereact/inputtext";
import {formattedTimeToMinutes, minutesToFormattedTime} from "../utilities/CommonUtils";
import {useState} from "react";

export default function FormattedTimeInput(props) {

  const [valid, setValid] = useState(true);

  return <>
    <InputText id={props.field}
               name={props.field}
               placeholder="...h ...m"
               defaultValue={minutesToFormattedTime(props.object[props.field])}
               className={valid ? "" : "p-invalid"}
               onBlur={event => {
                 if (event.target.value === "") {
                   setValid(true);
                   props.updateValidation(props.field, true);
                   props.handleChange(event);
                   return;
                 }
                 const minutes = formattedTimeToMinutes(event.target.value);
                 if (minutes > 0) {
                   setValid(true);
                   props.updateValidation(props.field, true);
                   event.target.value = minutesToFormattedTime(minutes);
                   props.handleChange(event, formattedTimeToMinutes);
                 } else {
                   setValid(false);
                   props.updateValidation(props.field, false);
                 }
               }}/>
    <small className="p-error" hidden={valid}>Nieprawidłowy format</small>
  </>

}
