import {useEffect, useMemo, useState} from 'react';
import Select from 'react-select';
import CreatableSelect from "react-select/creatable";
import {Divider} from "primereact/divider";
import {InputText} from "primereact/inputtext";
import {Button} from "primereact/button";
import {dateToString} from "../../utilities/CommonUtils";
import {GameService} from "../../service/GameService";
import {HistoryService} from "../../service/HistoryService";
import {Checkbox} from "primereact/checkbox";
import {RadioButton} from "primereact/radiobutton";
import {Tooltip} from "primereact/tooltip";
import FormattedTimeInput from "../../common/FormattedTimeInput";

export default function HistoryForm(props) {
  const emptyHistory = useMemo(() => ({
    dateOfGame: dateToString(new Date()),
    game: '',
    players: ['Ania', 'Tomek'],
    winners: [],
    remarks: '',
    coop: false,
    campaign: false,
    timeTaken: null,
  }), []);
  const [history, setHistory] = useState(emptyHistory);
  const [initialHistoryName, setInitialHistoryName] = useState('');
  const [gamesSuggestions, setGamesSuggestions] = useState([]);
  const [playersSuggestions, setPlayersSuggestions] = useState([]);
  const [winnersSuggestions, setWinnersSuggestions] = useState([]);
  const [showCoopCheckbox, setShowCoopCheckbox] = useState(false);
  const [showCampaignCheckbox, setShowCampaignCheckbox] = useState(false);
  const [suggestionInputValue, setSuggestionInputValue] = useState('');
  const [fieldValidation, setFieldValidation] = useState({});

  useEffect(() => {
    GameService.getNamesAndModes()
      .then(data => data.map(game => ({value: game.name, label: game.name, hasCoopMode: game.hasCoopMode, hasCampaignMode: game.hasCampaignMode})))
      .then(data => setGamesSuggestions(data));
  }, []);

  useEffect(() => {
    if (props.id) {
      HistoryService.get(props.id).then(history => {
        setHistory(history);
        setInitialHistoryName(history.game + ' / ' + history.dateOfGame);
      });
    } else {
      setHistory(emptyHistory);
    }
  }, [props, emptyHistory]);

  useEffect(() => {
    const playerSuggestions = props.playersSuggestions.map(player => ({value: player, label: player}));
    setPlayersSuggestions(playerSuggestions);
    setWinnersSuggestions(playerSuggestions.filter(suggestion => history.players.indexOf(suggestion.value) !== -1));
  }, [props, history.players]);

  useEffect(() => {
    const game = gamesSuggestions.find(game => game.value === history.game);
    setShowCoopCheckbox(game && game.hasCoopMode);
    setShowCampaignCheckbox(game && game.hasCampaignMode);
  }, [gamesSuggestions, history.game]);

  const handleSubmit = async (event) => {
    event.preventDefault();
    HistoryService.save(history).then(response => {
      if (response.ok) {
        props.onSuccessfulSubmit();
      } else {
        response.text().then(error => props.showFailureToast('Historia nie została zapisana. ' + error));
      }
    });
  }

  const handleChange = (event) => {
    const target = event.target;
    let {value, name} = target;
    const _history = {...history};
    _history[name] = value;
    setHistory(_history);
  }

  const handleChangeWithFormatter = (event, valueFormatter) => {
    const target = event.target;
    let {value, name} = target;
    const _history = {...history};
    _history[name] = valueFormatter ? valueFormatter(value) : value;
    setHistory(_history);
  }

  const handleGameSelectChange = (item) => {
    const _history = {...history};
    _history.game = item ? item.value : '';
    const _showCoopCheckbox = item && item.hasCoopMode;
    setShowCoopCheckbox(_showCoopCheckbox);
    if (!_showCoopCheckbox) {
      _history.coop = false;
    }
    const _showCampaignCheckbox = item && item.hasCampaignMode;
    setShowCampaignCheckbox(_showCampaignCheckbox);
    if (!_showCampaignCheckbox) {
      _history.campaign = false;
    }
    setHistory(_history);
  }

  const handlePlayersSelectChange = (items) => {
    const _history = {...history};
    _history.players = items ? items.map(item => item.value) : [];
    if (_history.coop && _history.winners.length > 0) {
      _history.winners = _history.players;
    }
    setHistory(_history);
    setWinnersSuggestions(items);
  }

  const handleWinnersSelectChange = (items) => {
    const _history = {...history};
    _history.winners = items ? items.map(item => item.value) : [];
    setHistory(_history);
  }

  const handleCoopChange = (event) => {
    const _history = {...history};
    _history.coop = event.checked;
    if (event.checked) {
      _history.winners = _history.players;
    } else {
      _history.winners = [];
    }
    setHistory(_history);
  }

  const handleCampaignChange = (event) => {
    const _history = {...history};
    _history.campaign = event.checked;
    setHistory(_history);
  }

  const handleCreatePlayer = (value) => {
    const _playersSuggestions = [...playersSuggestions];
    _playersSuggestions.push({label: value, value: value});
    setPlayersSuggestions(_playersSuggestions);
    const _winnersSuggestions = [...winnersSuggestions];
    _winnersSuggestions.push({label: value, value: value});
    setWinnersSuggestions(_winnersSuggestions);
    const _history = {...history};
    _history.players.push(value);
    setHistory(_history);
  }

  const handleReset = () => {
    setHistory({...emptyHistory});
    props.onClose();
  }

  const setCoopWin = () => {
    const _history = {...history};
    _history.winners = history.players;
    setHistory(_history);
  }

  const setCoopLose = () => {
    const _history = {...history};
    _history.winners = [];
    setHistory(_history);
  }

  const sortGameSuggestions = (inputValue) => {
    setSuggestionInputValue(inputValue);
    const _gamesSuggestions = [...gamesSuggestions];
    _gamesSuggestions.sort((first, second) => {
      if (inputValue) {
        const firstStartsWith = first.label.toLowerCase().startsWith(inputValue.toLowerCase());
        const secondStartsWith = second.label.toLowerCase().startsWith(inputValue.toLowerCase());
        if (firstStartsWith && !secondStartsWith) return -1;
        if (!firstStartsWith && secondStartsWith) return 1;
      }
      return first.label.localeCompare(second.label);
    });
    setGamesSuggestions(_gamesSuggestions);
  }

  const formatGameSuggestion = (game) => {
    const labelParts = game.label.split(new RegExp(`(${suggestionInputValue})`, 'gi'));
    return <span>
      {
        labelParts.map((part, i) => <span key={i} className={part.toLowerCase() === suggestionInputValue.toLowerCase() ? 'fw-bold' : ''}>{part}</span>)
      }
    </span>;
  };

  const updateValidation = (field, valid) => {
    const _fieldValidation = {...fieldValidation};
    _fieldValidation[field] = valid;
    setFieldValidation(_fieldValidation);
  }

  return (
    <div>
      <Tooltip target=".info-tooltip"/>
      <h3 className="text-center">{props.id ? 'Edytuj ' + initialHistoryName : 'Nowy wpis'}</h3>
      <Divider/>
      <div className="d-flex flex-column gap-2">
        <div className="d-flex flex-column">
          <label htmlFor="dateOfGame">Data</label>
          <input type="date" id="dateOfGame" name="dateOfGame" className="p-inputtext" value={history.dateOfGame || ''} onChange={handleChange}/>
        </div>
        <div className="d-flex flex-column">
          <label htmlFor="game">Gra</label>
          <Select id="game" name="game" placeholder="..."
                  options={gamesSuggestions}
                  onChange={handleGameSelectChange}
                  value={gamesSuggestions.find(suggestion => suggestion.value === history.game)}
                  isClearable={true}
                  isSearchable={true}
                  onInputChange={sortGameSuggestions}
                  formatOptionLabel={formatGameSuggestion}
          />
        </div>
        <div className="d-flex flex-column">
          <label htmlFor="players">Gracze</label>
          <CreatableSelect id="players" name="players" placeholder="..." formatCreateLabel={inputValue => 'Dodaj gracza: ' + inputValue}
                           isMulti={true}
                           options={playersSuggestions}
                           onChange={handlePlayersSelectChange}
                           onCreateOption={handleCreatePlayer}
                           value={playersSuggestions.filter(suggestion => history.players.indexOf(suggestion.value) !== -1)}
                           isClearable={true}
                           isSearchable={true}
          />
        </div>
        {showCoopCheckbox &&
          <div className="d-flex align-items-center gap-2">
            <label htmlFor="coop">Tryb co-op</label>
            <Checkbox id="coop" name="coop" checked={history.coop} onChange={handleCoopChange}/>
          </div>
        }
        {showCampaignCheckbox &&
          <div className="d-flex align-items-center gap-2">
            <label htmlFor="coop">Tryb kampanii</label>
            <Checkbox id="campaign" name="campaign" checked={history.campaign} onChange={handleCampaignChange}/>
          </div>
        }
        {!history.coop &&
          <div className="d-flex flex-column">
            <label htmlFor="winners">Zwycięzca</label>
            <Select id="winners" name="winners" placeholder="..."
                    isMulti={true}
                    options={winnersSuggestions}
                    onChange={handleWinnersSelectChange}
                    value={winnersSuggestions.filter(suggestion => history.winners.indexOf(suggestion.value) !== -1)}
                    isClearable={true}
                    isSearchable={true}
            />
          </div>
        }
        {history.coop &&
          <div className="d-flex flex-column">
            <label htmlFor="coopResult">Wynik</label>
            <div className="d-flex align-items-center gap-2">
              <RadioButton inputId="coopWin" name="coopResult" value="Wygrana" onChange={() => setCoopWin()} checked={history.winners.length > 0}/>
              <label htmlFor="coopResult">Wygrana</label>
              <RadioButton inputId="coopLose" name="coopResult" value="Przegrana" onChange={() => setCoopLose()} checked={history.winners.length === 0}/>
              <label htmlFor="coopLose">Przegrana</label>
            </div>
          </div>
        }
        <div className="d-flex flex-column">
          <label htmlFor="timeTaken">Czas <i className="info-tooltip pi pi-info-circle small"
                                             data-pr-tooltip="Całkowity czas spędzony przy grze, łącznie z rozkładaniem, tłumaczeniem i sprzątaniem"
                                             data-pr-classname="small"/></label>
          <FormattedTimeInput object={history} field={"timeTaken"} handleChange={handleChangeWithFormatter} updateValidation={updateValidation}/>
        </div>
        <div className="d-flex flex-column">
          <label htmlFor="remarks">Uwagi</label>
          <InputText id="remarks" name="remarks" value={history.remarks} onChange={handleChange}/>
        </div>
        <Divider/>
        <div className="d-flex gap-2 justify-content-center">
          <Button severity="primary" type="submit" onClick={handleSubmit}
                  disabled={fieldValidation && Object.values(fieldValidation).filter(valid => !valid).length > 0}>Zapisz</Button>
          <Button severity="secondary" type="reset" onClick={handleReset}>Anuluj</Button>
        </div>
      </div>
    </div>
  )
}
