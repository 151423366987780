import {BrowserRouter, Route, Routes} from "react-router-dom";
import GameList from "./page/games/GameList";
import GameGallery from "./page/gallery/GameGallery";
import HistoryList from "./page/history/HistoryList";
import WaitList from "./page/waitlist/WaitList";
import Stats from "./page/stats/Stats";
import './css/App.css';
import "primereact/resources/themes/bootstrap4-light-blue/theme.css"
import 'primereact/resources/primereact.css';
import 'primeicons/primeicons.css';
import {createContext, useEffect, useState} from "react";
import Menu from "./common/Menu";
import Ranking from "./page/ranking/Ranking";
import RankingEdit from "./page/ranking/RankingEdit";
import Admin from "./page/admin/Admin";
import TierList from "./page/tierlist/TierList";

export const AppContext = createContext(null);

export default function App() {

  const [authenticated, setAuthenticated] = useState(false);
  const [authorized, setAuthorized] = useState(false);
  const [email, setEmail] = useState('');
  const [person, setPerson] = useState(null);
  const [appVersion, setAppVersion] = useState(null);

  const appContextData = {authenticated, authorized, email, person, appVersion};

  useEffect(() => {
    const fetchData = async () => {
      const appInfo = await (await (fetch('/api/info'))).json();
      setAppVersion(appInfo.appVersion);
    };

    fetchData();
  }, [setAppVersion]);

  useEffect(() => {
    const fetchData = async () => {
      const authCheckResult = await (await (fetch('/api/auth/check'))).json();
      setAuthenticated(authCheckResult.authenticated);
      setAuthorized(authCheckResult.authorized);
      setEmail(authCheckResult.email);
      setPerson(authCheckResult.person);
    };

    fetchData();
  }, [setAuthenticated, setAuthorized, setEmail, setPerson]);

  return <AppContext.Provider value={appContextData}>
    <BrowserRouter>
      <Menu/>
      <Routes>
        <Route path='/' element={<GameGallery/>}/>
        <Route path='/games' element={<GameList/>}/>
        <Route path='/games/go/:goToId' element={<GameList/>}/>
        <Route path='/history' element={<HistoryList/>}/>
        <Route path='/history/go/:goToId' element={<HistoryList/>}/>
        <Route path='/waitlist' element={<WaitList/>}/>
        <Route path='/stats' element={<Stats/>}/>
        <Route path='/ranking' element={<Ranking/>}/>
        <Route path='/ranking/:year/edit' element={<RankingEdit/>}/>
        <Route path='/tierlist' element={<TierList/>}/>
        <Route path='/admin' element={<Admin/>}/>
      </Routes>
    </BrowserRouter>
  </AppContext.Provider>
}
