import {useCallback, useEffect, useRef, useState} from "react";
import {HexColorInput, HexColorPicker} from "react-colorful";
import "../css/ColorPicker.css"

const useClickOutside = (ref, handler) => {
  useEffect(() => {
    let startedInside = false;
    let startedWhenMounted = false;

    const listener = (event) => {
      if (startedInside || !startedWhenMounted) return;
      if (!ref.current || ref.current.contains(event.target)) return;
      handler(event);
    };

    const validateEventStart = (event) => {
      startedWhenMounted = ref.current;
      startedInside = ref.current && ref.current.contains(event.target);
    };

    document.addEventListener("mousedown", validateEventStart);
    document.addEventListener("touchstart", validateEventStart);
    document.addEventListener("click", listener);

    return () => {
      document.removeEventListener("mousedown", validateEventStart);
      document.removeEventListener("touchstart", validateEventStart);
      document.removeEventListener("click", listener);
    };
  }, [ref, handler]);
};

const textColorBasedOnBgColor = bgColor => {
  const color = (bgColor.charAt(0) === '#') ? bgColor.substring(1, 7) : bgColor;
  const r = parseInt(color.substring(0, 2), 16);
  const g = parseInt(color.substring(2, 4), 16);
  const b = parseInt(color.substring(4, 6), 16);
  const colorThreshold = (r * 0.299) + (g * 0.587) + (b * 0.114);
  return colorThreshold > 150 ? "#000000" : "#ffffff";
};

export const ColorPicker = ({color, onChange, text}) => {
  const popup = useRef();
  const [isOpen, setIsOpen] = useState(false);

  const close = useCallback(() => setIsOpen(false), []);
  useClickOutside(popup, close);

  return (
    <div className="color-picker-container">
      <div className="color-picker-swatch" style={{backgroundColor: color, color: textColorBasedOnBgColor(color)}} onClick={() => setIsOpen(true)}>
        {text}
      </div>

      {isOpen && (
        <div className="color-picker-popover text-center" ref={popup}>
          <HexColorPicker color={color} onChange={onChange}/>
          <HexColorInput color={color} onChange={onChange} prefixed={true} className="text-center w-100"/>
        </div>
      )}
    </div>
  );
};
